import styled, { css } from 'styled-components'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'
import { motion } from 'framer-motion'

const { white, black } = colors

export const StyledNavigationWrapper = styled.nav`
  position: absolute;
  width: 100%;
  z-index: 10;
`

export const StyledNavigation = styled(StyledCenteredContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
`

export const StyledLogoContainer = styled.div`
  z-index: 1;
  cursor: pointer;
  @media (max-width: 900px) {
    svg {
      height: 1.875rem;
      width: 6.0944rem;
    }
  }
`

export const StyledNavigationItemsContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 1156px) {
    display: none;
    visibility: hidden;
  }
`
export const StyledNavigationItem = styled.li`
  color: transparent;
  list-style-type: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    top: 0;
    left: 0;
    color: ${white};
    transition: all 0.5s ease-in-out;

    ${({ iscurrent }) =>
      iscurrent &&
      css`
        color: ${({ color }) => color};
      `}

    &:hover {
      ${({ iscurrent }) =>
        !iscurrent &&
        css`
          transform: translateY(-100%);
        `}
    }

    &::after {
      content: ${({ content }) => `'${content}'`};
      display: block;
      position: absolute;
      top: 100%;
      width: 100%;
      height: 100%;
      color: ${({ color }) => color};
    }
  }
`

export const StyledContactButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
`

export const StyledContactButton = styled.div`
  @media (max-width: 1156px) {
    display: none;
    visibility: hidden;
  }
`

export const StyledMenuLine = styled.div`
  position: absolute;
  width: ${({ shorter }) => (shorter ? '13px' : '20px')};
  min-height: 0.0625rem;
  border-radius: 3.125rem;
  background-color: ${black};
  margin: 6px 0.75rem;

  //@media (prefers-color-scheme: dark) {
  //  filter: invert(1);
  //}
`

export const StyledBurgerContainer = styled(motion.div)`
  position: relative;
  z-index: 2;
`

export const StyledBurger = styled.button`
  position: relative;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
  background: ${white};
  z-index: 2;
  padding-left: 0.2rem;

  //@media (prefers-color-scheme: dark) {
  //  filter: invert(1);
  //}

  &:focus {
    outline: none;
  }

  ${({ open }) =>
    open &&
    css`
      transform: rotate(45deg);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    `};

  div {
    transition: all 0.3s linear;
    //transform-origin: 0.0625rem;
    height: 0.125rem;
    padding: 0;

    &:first-child {
      transform: rotate(0);
      width: 1.25rem;
    }

    &:nth-child(2) {
      opacity: 1;
      bottom: 1.1rem;

      @media (max-width: 600px) {
        bottom: 1.05rem;
      }

      transform: translateX(0);
      width: 1.25rem;
    }

    &:nth-child(3) {
      transform: rotate(0);
      width: 0.8125rem;
      bottom: 1.5rem;
    }

    ${({ open }) =>
      open &&
      css`
        height: 0.0313rem;
        padding: 0.0625rem;
        bottom: unset;

        &:first-child {
          margin: 0;
          width: 50%;
          position: absolute;
        }

        &:nth-child(2) {
          opacity: 0;
          margin: 0;
          transform: translateY(-8px);
          width: 1.4375rem;
        }

        &:nth-child(3) {
          transform: rotate(90deg);
          width: 50%;
          margin: 0;
        }
      `};
  }
`
