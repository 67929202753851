import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ResponsivePagination from 'react-responsive-pagination'
import { HashLink } from '@xzar90/react-router-hash-link'

import {
  StyledArticleListWrapper,
  StyledArticleList,
  StyledArticleCard,
  StyledArticleTitle,
  StyledServicesNav,
  StyledArticleListNotch,
  StyledArticleDescription
} from './ArticleList.styled'

import 'react-responsive-pagination/themes/minimal.css'

const ArticleList = ({ articles }) => {
  const totalItems = articles?.length || 0
  const totalPages = Math.ceil(totalItems / 8)

  const [itemsPerPage] = useState(8)
  const [currentPage, setCurrentPage] = useState(1)

  const lastArticleIndex = currentPage * itemsPerPage
  const firstArticleIndex = lastArticleIndex - itemsPerPage
  const currentArticles = articles.slice(firstArticleIndex, lastArticleIndex)

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <StyledArticleListWrapper>
      <StyledArticleListNotch />
      <StyledArticleList>
        {currentArticles.map(
          ({
            _key,
            title: articleTitle,
            articleUrl,
            midiaName,
            description
          }) => {
            return (
              <StyledArticleCard key={_key}>
                <StyledArticleTitle>{articleTitle}</StyledArticleTitle>
                <StyledArticleDescription>
                  {description}
                </StyledArticleDescription>
                <HashLink
                  to={articleUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledServicesNav content={`Ver a matéria em ${midiaName}`}>
                    Ver a matéria em {midiaName} {'->'}
                    <span>Ver a matéria em {midiaName} →</span>
                  </StyledServicesNav>
                </HashLink>
              </StyledArticleCard>
            )
          }
        )}
      </StyledArticleList>

      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={handlePageChange}
      />
    </StyledArticleListWrapper>
  )
}

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({ children: PropTypes.node }))
    .isRequired
}

export default ArticleList
