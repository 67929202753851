import { useContext } from 'react'
import { PortableText } from '@portabletext/react'
import { getFileUrl } from 'sanityClient.js'

import { HelmetSEO, ArticleList } from 'components'
import { Context } from 'store'

import {
  StyledNewsWrapper,
  StyledNewsContent,
  StyledNewsDescription,
  StyledNewsBackground,
  StyledNewsDetailsBackground,
  StyledNewsTitle
} from './News.styled'

const helmetCopy = {
  title: 'Cheil na mídia: Destaques e Inovações na Publicidade | Cheil Brasil',
  canonical: '/cheil-na-midia',
  description:
    'A Cheil do Brasil se destaca na mídia com campanhas criativas que geram resultados e inovação.',
  keywords: 'Cheil na mídia'
}
const News = () => {
  const {
    state: { news }
  } = useContext(Context)

  const { title, description, articles, hero_image } = news

  return (
    <>
      <HelmetSEO copy={helmetCopy} />

      {hero_image && (
        <StyledNewsBackground bgimage={getFileUrl(hero_image.asset._ref)} />
      )}

      <StyledNewsWrapper>
        <StyledNewsDetailsBackground>
          <StyledNewsContent>
            <StyledNewsTitle>{title}</StyledNewsTitle>
            <PortableText
              value={description}
              components={{
                block: { normal: StyledNewsDescription }
              }}
            />
          </StyledNewsContent>
        </StyledNewsDetailsBackground>

        <ArticleList articles={articles} />
      </StyledNewsWrapper>
    </>
  )
}

export default News
