import styled from 'styled-components'
import { Body1 } from 'assets/common/typography'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { white, black111, mint } = colors

export const StyledNewsBackground = styled.div`
  background: url(${({ bgimage }) => bgimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-height: 45rem;

  @media (min-width: 2100px) {
    min-height: 100vh;
  }

  @media (max-width: 900px) {
    min-height: 21.875rem;
  }
`

export const StyledNewsDetailsBackground = styled.div`
  background: ${mint};
  color: ${black111};
`

export const StyledNewsWrapper = styled.div`
  background-color: ${white};
`
export const StyledNewsContent = styled(StyledCenteredContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: -2rem;
  }
`
export const StyledNewsTitle = styled.h1`
  max-width: 3rem;
`

export const StyledNewsDescription = styled(Body1)`
  margin: 0 12% 0 0;
  width: 40%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`
