import React from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet-async'

const HelmetSEO = ({ copy }) => {
  return (
    <Helmet defaultTitle="Cheil Brasil">
      {/* <!-- SEO --> */}
      {copy.title && (
        <title itemProp="name" lang="pt-BR">
          {copy.title}
        </title>
      )}
      {copy.description && (
        <meta name="description" content={copy.description} />
      )}
      {copy.keywords && <meta name="keywords" content={copy.keywords} />}
      {copy.canonical && (
        <link
          rel="canonical"
          href={`https://www.cheil.com.br${copy.canonical}`}
        />
      )}

      {/* <!-- Open Graph Tags --> */}
      {copy.title && <meta property="og:title" content={copy.title} />}
      {copy.description && (
        <meta property="og:description" content={copy.description} />
      )}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.cheil.com.br/contato/" />
      <meta
        property="og:image"
        content="https://www.cheil.com.br/logo192.png"
      />

      {/* <!-- Twitter Card Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      {copy.title && <meta name="twitter:title" content={copy.title} />}
      {copy.description && (
        <meta name="twitter:description" content={copy.description} />
      )}
      <meta
        name="twitter:image"
        content="https://www.cheil.com.br/logo192.png"
      />

      {/* <!-- Google Search Console --> */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-RXSGMLMZ4X"
      />

      {/* <!-- Google tag (gtag.js) --> */}
      <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-RXSGMLMZ4X');
      `}
      </script>
    </Helmet>
  )
}

HelmetSEO.propTypes = {
  copy: PropTypes.objectOf(PropTypes.string).isRequired
}
export default HelmetSEO
