export const MAIN_MENU = [
  'sobre',
  'work',
  'serviços',
  'pessoas',
  'cheil na mídia'
]
export const SUB_MENU = ['home', 'contato', 'vagas']
export const SERVICES = [
  { label: 'Campanha', value: 'campanha', key: '57530d303383' },
  { label: 'Conteúdo', value: 'conteúdo', key: 'f1c1d9e0c8d4' },
  { label: 'Game', value: 'game', key: '21f22787f037' },
  { label: 'Retail', value: 'retail', key: '3cd5d747e691' }
]
