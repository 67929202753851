import styled from 'styled-components'
import { colors } from 'assets/common/colors'
import { Body1 } from 'assets/common/typography'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { black111, mint } = colors

export const StyledArticleListWrapper = styled(StyledCenteredContainer)`
  color: ${black111};

  @media (min-width: 320px) {
    padding: 0 0 5rem 0;
  }
`
export const StyledArticleListNotch = styled.div`
  background-color: ${mint};
  width: 50px;
  height: 50px;
  rotate: 45deg;
  z-index: 5;
  position: absolute;
  margin-top: -2rem;
  margin-left: 90%;

  @media (max-width: 700px) {
    margin-left: 80%;
  }
`

export const StyledArticleList = styled.ul`
  color: ${black111};
  display: flex;
  flex-wrap: wrap;
  justify-content: baseline;
  margin: 0;
  cursor: pointer;
  gap: 0.2rem;
  max-width: 90%;
  padding: 5rem 0 5rem 5rem;

  @media (max-width: 700px) {
    flex-direction: column;
    max-width: 100%;
    padding: 2rem;
  }
`

export const StyledArticleCard = styled.li`
  flex: 1 1 calc(50% - 0.2rem);
  box-sizing: border-box;
  list-style: none;
  padding: 0 7rem 2rem 0;
  min-height: 18rem;
  max-width: 50%;

  @media (max-width: 700px) {
    min-height: 0;
    padding: 0 0 3rem 0;
    max-width: 100%;
  }
`

export const StyledArticleTitle = styled.p`
  font-family: 'Montserrat', Arial, Sans-Serif;
  font-size: 1.5rem; // 34px
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.06375rem;
  margin: 0 1rem 1rem 0;
  padding: 0;

  @media (max-width: 900px) {
    font-size: 1.41669rem;
    letter-spacing: -0.0425rem;
  }

  @media (max-width: 900px) {
    font-size: 2.125rem;
    width: 100%;
  }
`

export const StyledArticleDescription = styled.p`
  font-family: 'Inter', Arial, Sans-Serif;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.0325rem;

  @media (max-width: 900px) {
    font-size: 1.125rem;
    letter-spacing: -0.0225rem;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const StyledServicesNav = styled(Body1)`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  color: transparent;
  font-size: 0.95rem;
  line-height: 150%;
  letter-spacing: -0.03rem;
  margin-bottom: 1rem;
  font-weight: 700;

  span {
    position: absolute;
    top: 0;
    left: 0;
    color: ${mint};
    transition: all 0.5s ease-in-out;

    &:hover {
      transform: translateY(-100%);
    }

    &::after {
      content: ${({ content }) => `'${content}  → '`};
      display: block;
      position: absolute;
      top: 100%;
      height: 100%;
      color: ${black111};
    }
  }
`
